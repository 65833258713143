<template>
  <section>
    <a-form class="form-container" :form="form" @submit="submitForm">
      <a-row type="flex" :gutter="20">
        <a-col :span="8">
          <!--   :default-value="defaultValue" -->
          <a-form-item label="所属专业">
            <a-cascader
              :options="options"
              @change="(value) => onChangeMaterial(value)"
              placeholder="请选择"
              v-decorator="[
                'categoryId',
                { rules: [{ required: true, message: '所属专业不能为空' }] },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="材料名称">
            <a-input
              autocomplete="off"
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '材料名称不能为空' }] },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="计量单位 ">
            <a-input
              v-decorator="[
                'unit',
                { rules: [{ required: true, message: '计量单位不能为空' }] },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <section v-if="!id">
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="材料品牌 ">
              <a-input
                :disabled="item.status == '0' ? false : true"
                v-decorator="[
                  'material_' + item.key,
                  {
                    rules: [
                      {
                        required: true,
                        message:
                          '请录入合同上标注的品牌全称/品牌简称，如：广东一通钢管工业有限公司/一通牌',
                      },
                    ],
                  },
                ]"
                v-for="item in materialsList"
                :key="item.key"
              >
                <span slot="addonAfter" class="edit" v-if="item.status">
                  <!-- <span class="m-r-10">
                    <a-icon
                      @click="editMaterial(item)"
                      v-if="item.status == '1'"
                      type="edit"
                    />
                    <a-icon v-else @click="saveMaterial(item)" type="save" />
                  </span> -->

                  <span>
                    <a-icon @click="deleteMaterial(item)" type="close-circle" />
                  </span>
                </span>
              </a-input>
            </a-form-item>
            <a-button class="add" @click="addMaterials"
              >添加</a-button
            >
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="20">
          <a-col :span="8">
            <a-form-item label="规格型号 ">
              <a-input
                :disabled="item.status == '0' ? false : true"
                v-decorator="[
                  'type_' + item.key,
                  { rules: [{ required: true, message: '材料品牌不能为空' }] },
                ]"
                v-for="item in specificationList"
                :key="item.key"
              >
                <span slot="addonAfter" class="edit" v-if="item.status">
                  <!-- <span class="m-r-10">
                    <a-icon
                      @click="editSpecification(item)"
                      v-if="item.status == '1'"
                      type="edit"
                    />
                    <a-icon
                      v-else
                      @click="saveSpecification(item)"
                      type="save"
                    />
                  </span> -->

                  <span>
                    <a-icon
                      @click="deleteSpecification(item)"
                      type="close-circle"
                    />
                  </span>
                </span>
              </a-input>
              <!-- <a-input></a-input>
            <a-input></a-input>
            <a-input></a-input>
            <a-input></a-input> -->
            </a-form-item>
            <a-button @click="addspecification" class="add"
              >添加</a-button
            >
          </a-col>
        </a-row>
      </section>

      <section v-else>
        <a-row type="flex" :gutter="20">
          <a-col :span="16">
            <a-form-item label="材料品牌/规格型号">
              <section class="list-table-container list-table">
                <a-table
                  :bordered="false"
                  :data-source="list.data"
                  size="small"
                  class="m-t-18"
                  :columns="list.columns"
                  :pagination="false"
                >
                  <template slot="brand" slot-scope="list, text">
                    <a-input
                      :disabled="text.status == '0' ? true : false"
                      v-model="text.brand"
                    />
                  </template>
                  <template slot="type" slot-scope="list, text">
                    <a-input
                      :disabled="text.status == '0' ? true : false"
                      v-model="text.type"
                    />
                  </template>
                  <template slot="operation" slot-scope="list, text">
                    <span>
                      <span v-if="text.saveStatus == '1'">
                        <a style="color: #529aff" @click="savaTableData(text)"
                          >保存</a
                        >
                        <a-divider style="color: #222" type="vertical" />
                      </span>
                      <span v-if="text.editStatus == '0'">
                        <a style="color: #529aff" @click="editTableData(text)"
                          >修改</a
                        >
                        <a-divider style="color: #222" type="vertical" />
                      </span>

                      <a
                        @click="delTableData(text)"
                        style="color: #529aff"
                        v-if="text.delStatus == '1'"
                        >删除</a
                      >
                    </span>
                  </template>
                </a-table>
              </section>
            </a-form-item>

            <a-button class="add" @click="addTableMaterials"
              >添加</a-button
            >
          </a-col>
        </a-row>
      </section>
      <a-row>
        <a-col :span="16">
          <a-form-item label="备注">
            <a-textarea v-model="remarks" rows="4"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-btn-group">
        <a-col :span="16">
          <a-button html-type="submit"><a-icon type="save" />保存</a-button>
          <a-button @click="$router.back()">取消</a-button>
        </a-col>
      </a-row>
    </a-form>
  </section>
</template>

<script>
export default {
  data() {
    return {
      materialsList: [],
      specificationList: [],
      totalListData: [],
      index: 0,
      specificationIndex: 0,
      warehouseId: "",
      options: [],
      remarks: "",
      defaultValue: [],
      id: "",
      skuList: [],
      materialIdList: [],
      idList: [],
      list: {
        total: 0,
        data: [],
        pageNum: 1,
        columns: [
          {
            title: "材料品牌",
            dataIndex: "brand",
            align: "center",
            scopedSlots: { customRender: "brand" },
          },
          {
            title: "规格型号",
            dataIndex: "type",
            align: "center",
            scopedSlots: { customRender: "type" },
          },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          },
        ],
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.getCategoryList();
    this.getMaterialInfoDetail();
  },
  methods: {
    // 分类列表
    getCategoryList() {
      this.$api.getCategoryList().then((res) => {
        if (res.code == "200") {
          let options = res.data;
          let arr = this.formTreeData(options);
          this.options = arr;
        }
      });
    },
    // 详情
    getMaterialInfoDetail() {
      this.id = this.$route.query.id;

      if (!this.id) return;
      this.$api.getMaterialInfoDetail(this.id).then((res) => {
        if (res.code == "200") {
          this.remarks = res.data.remarks;
          this.skuList = res.data.skuList ? res.data.skuList : [];
          let material = [];
          let type = [];

          // 处理数据
          this.skuList.forEach((item, index) => {
            item[`material_${index + 1}`] = `${item.brand}`;
            item.key = index + 1;
            this.materialIdList.push(item.materialId);
            this.idList.push(item.id);
            material.push((item[`material_${index + 1}`] = `${item.brand}`));
            type.push((item[`type_${index + 1}`] = `${item.type}`));

            // 回填 材料
            let material_obj = {};
            material_obj[`material_${index + 1}`] = item.brand;
            material_obj[`key`] = index + 1;
            material_obj[`id`] = item.id;
            material_obj[`materialId`] = item.materialId;
            this.materialsList.push(material_obj);

            // 回填 规格
            let type_obj = {};
            type_obj[`type_${index + 1}`] = item.type;
            type_obj[`key`] = index + 1;
            this.specificationList.push(type_obj);

            // 回填表格
            item.status = "0";
            item.editStatus = "0";
            item.delStatus = "1";
            item.saveStatus = "0";
            item.delFlag = "0"; //删除
          });
          this.list.data = res.data.skuList ? res.data.skuList : [];
          this.totalListData = res.data.skuList ? res.data.skuList : [];
          console.log(this.list.data, "this.list.data");
          // this.materialsList = this.skuList;
          // this.specificationList = this.skuList;
          // console.log(this.specificationList, this.materialsList);
          this.$nextTick(() => {
            this.form.setFieldsValue({
              categoryId: [res.data.categoryParentId, res.data.categoryId],
              name: res.data.name,
              unit: res.data.unit,
            });

            // let table=[]

            // 回填 材料
            // let material_Arr = {};

            // material.forEach((item, index) => {
            //   material_Arr[`material_${index + 1}`] = item;
            // });
            // // 回填 规格
            // let type_Arr = {};
            // type.forEach((item, index) => {
            //   type_Arr[`type_${index + 1}`] = item;
            // });
            // console.log(material_Arr, type_Arr);
            // // 回填
            // this.form.setFieldsValue(material_Arr);

            // this.form.setFieldsValue(type_Arr);
          });
        }
      });
    },

    // 选择材料
    onChangeMaterial(value) {
      this.categoryId = value[value.length - 1];
      console.log(this.categoryId);
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then((res) => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
        }
      });
    },
    submitForm(e) {
      e && e.preventDefault();
      this.form.validateFields((err, formData) => {
        if (!err) {
          //`/${data}`

          if (this.id) {
            // console.log("修改啊");
            // console.log(
            //   formData,
            //   this.materialsList,
            //   this.specificationList,
            //   this.materialIdList,
            //   this.idList
            // );
            console.log(this.list.data);
            let skuList = [];
            this.list.data.forEach((item) => {
              let data = {
                brand: item.brand,
                id: item.id ? item.id : "",
                materialId: item.materialId ? item.materialId : "",
                type: item.type,
                delFlag: item.delFlag,
              };
              skuList.push(data);
            });
            this.totalListData.forEach((item) => {
              if (item.delFlag == "1") {
                let data = {
                  brand: item.brand,
                  id: item.id ? item.id : "",
                  materialId: item.materialId ? item.materialId : "",
                  type: item.type,
                  delFlag: item.delFlag,
                };
                skuList.push(data);
              }
            });
            console.log(skuList, this.totalListData);
            let params = {
              skuList: skuList,
              categoryId: formData.categoryId[formData.categoryId.length - 1],
              id: this.id,
              name: formData.name,
              unit: formData.unit,
              remarks: this.remarks,
            };
            this.$api.editMaterialInfoDetail(params).then((res) => {
              if (res.code == "200") {
                // console.log(res);
                this.$router.push({ path: "/mms/majorMangerList" });
              }
            });
          } else {
            let materialArr = [];
            let materialData = [];
            let typeArr = [];
            let typeData = [];
            this.materialsList.forEach((item) => {
              materialArr.push(`material_${item.key}`);
            });

            this.specificationList.forEach((item) => {
              typeArr.push(`type_${item.key}`);
            });

            materialArr.forEach((items) => {
              materialData.push(formData[items]);
            });
            typeArr.forEach((items) => {
              typeData.push(formData[items]);
            });

            let params = {
              categoryId: formData.categoryId[formData.categoryId.length - 1],
              brand: materialData.join(","),
              name: formData.name,
              type: typeData.join(","),
              unit: formData.unit,
              remarks: this.remarks,
            };
            console.log(params);
            this.$api.addMaterialinfo(params).then((res) => {
              if (res.code == "200") {
                // console.log(res);
                this.$router.push({ path: "/mms/majorMangerList" });
              }
            });
          }
        }
      });
    },
    // 添加材料品牌
    addMaterials() {
      // this.index = this.materialsList.length;
      this.index =
        this.materialsList.length > 0
          ? this.materialsList[this.materialsList.length - 1].key
          : 0;
      let materialsList = { status: "0", key: this.index + 1, brand: "" };
      //debugger;
      this.materialsList.push(materialsList);
    },
    // 添加规则说明
    addspecification() {
      this.specificationIndex =
        this.specificationList.length > 0
          ? this.specificationList[this.specificationList.length - 1].key
          : 0;
      let specificationList = {
        status: "0",
        key: this.specificationIndex + 1,
        type: "",
      };
      this.specificationList.push(specificationList);
      console.log(this.specificationList);
    },

    // 保存材料品牌
    saveMaterial(params) {
      this.materialsList.forEach((item) => {
        if (item.key == params.key) {
          item.status = "1";
        }
      });
    },
    // 保存规格说明
    saveSpecification(params) {
      this.specificationList.forEach((item) => {
        if (item.key == params.key) {
          item.status = "1";
        }
      });
    },
    // 修改材料品牌
    editMaterial(params) {
      this.materialsList.forEach((item) => {
        if (item.key == params.key) {
          item.status = "0";
        }
      });
    },
    // 修改规则说明
    editSpecification(params) {
      this.specificationList.forEach((item) => {
        if (item.key == params.key) {
          item.status = "0";
        }
      });
    },
    // 删除材料品牌
    deleteMaterial(params) {
      let listMap = [...this.materialsList];
      let arr = [];
      listMap.forEach((item) => {
        if (item.key != params.key) {
          arr.push(item);
        }
      });
      // this.materialsList.forEach((item) => {
      //   if (item.key != params.key) {
      //     arr.push(item);
      //   }
      // });
      this.materialsList = arr;
    },
    // 删除规格说明
    deleteSpecification(params) {
      let listMap = [...this.specificationList];
      let arr = [];
      // listMap = listMap.filter((item) => {
      //   if (item.key != params.key) return item;
      // });
      listMap.forEach((item) => {
        if (item.key != params.key) {
          arr.push(item);
        }
      });
      // listMap.forEach((item, index) => {
      //   item.key = index + 1;
      // });
      this.specificationList = arr;
      console.log(listMap, params, this.specificationList);
    },
    // 添加表格材料信息
    addTableMaterials() {
      console.log(this.list.data);
      let l =
        this.list.data.length > 0
          ? this.list.data[this.list.data.length - 1].key
          : 0;
      let specificationList = {
        status: "1",
        key: l + 1,
        material: "",
        saveStatus: "1",
        editStatus: "1",
        delStatus: "1",
        type: "",
        delFlag: "0",
      };
      this.list.data.push(specificationList);
    },
    // 保存表格
    savaTableData(item) {
      let listMap = [...this.list.data];

      listMap.forEach((items) => {
        if (items.key == item.key) {
          item.saveStatus = "0";
          item.editStatus = "0";
          item.status = "0";
        }
      });
      this.list.data = listMap;
      // item.saveStatus = "0";
      // item.editStatus = "0";
      // item.status = "0";
    },
    // 修改表格
    editTableData(item) {
      let listMap = [...this.list.data];
      listMap.forEach((items) => {
        if (items.key == item.key) {
          item.saveStatus = "1";
          item.editStatus = "1";
          item.status = "1";
        }
      });
      this.list.data = listMap;

      // console.log(this.list.data, item, "materialsList");
      // item.saveStatus = "1";
      // item.editStatus = "1";
      // item.status = "1";
    },
    // 删除表格
    delTableData(item) {
      if (item.id) {
        this.$api.deleteMaterialsku(item.id).then((res) => {
          if (res.code == "200") {
            // 处理删除已存在材料
            this.totalListData.forEach((items) => {
              if (items.key == item.key) {
                items.delFlag = "1";
              }
            });
            this.list.data = this.list.data.filter((items) => {
              if (items.key != item.key) return items;
            });
            // console.log(this.list.data, item, "materialsList",this.totalListData);
          }
        });
      } else {
        this.list.data = this.list.data.filter((items) => {
          if (items.key != item.key) return items;
        });
      }
    },
    // 树状图
    formTreeData(arr) {
      arr.forEach((item) => {
        item.value = item.id;
        item.label = item.name;

        item.disabled = item.children ? false : true;
        // item.scopedSlots = { title: "value" };
        if (item.children && item.children.length > 0) {
          // this.formTreeData(item.children);
          item.children.forEach((v) => {
            v.value = v.id;
            v.label = v.name;
          });
        }
      });

      return arr;
    },
  },
};
</script>
<style lang="scss" scoped>
.add {
  background: #3f72ba;
  border: none;
  color: #ffffff;
  margin-right: 20px;
}
/deep/.ant-input-group-addon {
  background: #3f72ba;
  border: 0;
}
/deep/.list-table .ant-input {
  text-align: center;
}
/deep/.ant-input[disabled] {
  color: rgba(255, 255, 255, 0.8);
  background-color: #1c3d6c;
}
/deep/.anticon {
  font-size: 16px;
}
/deep/.ant-cascader-picker-label {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker {
  background-color: #1c3d6c;
  border: none;
  outline: none;
}
/deep/.ant-input {
  background-color: #1c3d6c;
}
/deep/.ant-input:hover {
  background-color: #1c3d6c;
}

/deep/.ant-cascader-picker-arrow {
  color: #93bbf3 !important;
}
.edit,
.del {
  cursor: pointer;
}
</style>